<template>
  <section>
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-actions class="d-flex justify-end">
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
        <div class="d-flex justify-center mb-6">
          <v-avatar color="secondary">
            <v-icon large color="white">mdi-check</v-icon>
          </v-avatar>
        </div>

        <div class="d-flex justify-center pb-6">
          <span class="font-weight-bold ">
            Seus dados foram enviados para análise!
          </span>
        </div>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    open() {
      this.dialog = true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
