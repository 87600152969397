<template>
  <v-container class="py-12">
    <v-card color="foreground" class="rounded-xl">
      <v-sheet class="rounded-t-xl gradient" height="192" />

      <div
        class="d-flex flex-column flex-md-row align-center mb-6 mb-md-12 mt-n6 px-12"
      >
        <v-badge
          class="mr-md-6"
          color="primary"
          icon="mdi-camera"
          offset-x="32"
          offset-y="24"
          bordered
          overlap
          bottom
        >
          <v-avatar
            class="pointer"
            color="grey lighten-4"
            size="128"
            @click="handleChangeImage()"
          >
            <v-img style="border: 2px solid white" :src="_profilePicture" />
          </v-avatar>
        </v-badge>

        <input
          ref="uploader"
          class="d-none"
          type="file"
          accept="image/*"
          @change="submitProfileImage($event)"
        />

        <div class="d-flex flex-column">
          <div class="text-h4 font-weight-bold secondary--text">
            <span >
              {{ user.name }}
            </span>
          </div>
          <div class="text-h6 font-weight-regular subtext--text">
            <span >
              {{ user.email }}
            </span>
          </div>
        </div>
      </div>

      <v-form v-model="valid" ref="form" class="px-6 px-md-12 pb-md-8">
        <v-row>
          <v-col cols="12" md="6">
            <div class="font-weight-bold secondary--text mb-4">
              <span > Informações pessoais </span>
            </div>

            <custom-input
              v-model="formPersonal.name"
              ref="name"
              label="Nome completo ou razão social"
              placeholder="Seu nome / razão social"
              outlined
              @keypress="blockNumber($event)"
            />

            <custom-mask-input
              v-model="formPersonal.document"
              :mask="['###.###.###-##', '##.###.###/####-##']"
              ref="document"
              label="CPF ou CNPJ"
              placeholder="CPF / CNPJ"
              outlined
            />

            <custom-mask-input
              v-model="formPersonal.birthDate"
              :mask="'##/##/####'"
              ref="birthDate"
              label="Data de Nascimento"
              placeholder="##/##/####"
              outlined
            />

            <custom-mask-input
              v-model="formPersonal.phone"
              :mask="'(##) #####-####'"
              ref="phone"
              label="Celular"
              placeholder="(##) #####-####"
              outlined
            />

            <custom-input
              v-model="formPersonal.profession"
              ref="profession"
              label="Profissão"
              placeholder="Sua profissão"
              outlined
              @keypress="blockNumber($event)"
            />
          </v-col>

          <v-col cols="12" md="6">
            <div class="font-weight-bold secondary--text mb-4">
              <span > Logradouro </span>
            </div>

            <v-row no-gutters>
              <v-col cols="12" md="6" class="pr-md-2">
                <custom-mask-input
                  v-model="formAddress.zipCode"
                  ref="zipCode"
                  label="CEP"
                  placeholder="#####-###"
                  :mask="'#####-###'"
                  outlined
                />
              </v-col>

              <v-col cols="12" md="6" class="pl-md-2">
                <custom-mask-input
                  v-model="formAddress.state"
                  ref="state"
                  label="Estado"
                  placeholder="Sigla do seu estado"
                  :mask="'AA'"
                  outlined
                  @keypress="blockNumber($event)"
                />
              </v-col>
            </v-row>

            <custom-input
              v-model="formAddress.city"
              ref="city"
              label="Cidade"
              placeholder="Informe sua cidade"
              outlined
              @keypress="blockNumber($event)"
            />

            <custom-input
              v-model="formAddress.neighborhood"
              ref="neighborhood"
              label="Bairro"
              placeholder="Informe seu bairro"
              outlined
              @keypress="blockNumber($event)"
            />

            <v-row no-gutters>
              <v-col cols="12" md="9" class="pr-md-2">
                <custom-input
                  v-model="formAddress.street"
                  ref="street"
                  label="Endereço"
                  placeholder="Escreva o nome da sua rua"
                  outlined
                />
              </v-col>

              <v-col cols="12" md="3" class="pl-md-2">
                <custom-mask-input
                  v-model="formAddress.number"
                  :mask="'######'"
                  ref="number"
                  label="Número"
                  placeholder="Número"
                  outlined
                />
              </v-col>
            </v-row>

            <custom-input
              v-model="formAddress.complement"
              ref="complement"
              label="Complemento"
              placeholder="Complemento"
              :required="false"
              outlined
            />
          </v-col>
        </v-row>

        <div class="font-weight-bold secondary--text mb-4">
          <span > Outras informações </span>
        </div>

        <v-row>
          <v-col cols="12" md="4">
            <custom-money-input
              v-model="formPersonal.incomeEstimate"
              id="incomeEstimate"
              ref="incomeEstimate"
              label="Renda total familiar"
              placeholder="Type Here"
              outlined
            />
          </v-col>

          <v-col cols="12" md="4">
            <custom-select
              v-model="formPersonal.liquidity"
              ref="liquidity"
              label="Liquidez"
              placeholder="Selecione"
              itemText="label"
              itemValue="value"
              :items="liquidityItems"
              outlined
            />
          </v-col>

          <v-col cols="12" md="4">
            <custom-select
              v-model="formPersonal.contactType"
              ref="contactType"
              label="Preferência de contato"
              placeholder="Selecione"
              itemText="label"
              itemValue="value"
              :items="contactItems"
              outlined
            />
          </v-col>
        </v-row>

        <div class="d-flex mb-2">
          <label class="font-weight-bold secondary--text">
            <span > Dados bancários </span>
          </label>

          <v-spacer />

          <v-btn color="primary" text rounded @click="addNewPixKey()">
            Adicionar
            <v-icon right>mdi-plus</v-icon>
          </v-btn>
        </div>

        <v-row v-for="(item, i) in formPix" align="center" :key="i">
          <v-col cols="12" md="4">
            <custom-select
              v-model="item.keyType"
              ref="keyType"
              label="Tipo de chave"
              placeholder="Selecione o tipo da chave"
              :items="pixTypes"
              :readonly="item.id !== ''"
              outlined
            />
          </v-col>

          <v-col cols="10" md="7">
            <custom-input
              v-model="item.pixKey"
              ref="pixKey"
              label="Chave Pix"
              placeholder="Cadastre sua chave pix"
              :readonly="item.id !== ''"
              outlined
            />
          </v-col>

          <v-col cols="1">
            <v-btn
              class="mb-2"
              :disabled="formPix.length === 1"
              icon
              @click="removeBankAccount(i)"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6">
            <card-file-input
              v-model="formPersonal.identityProof"
              ref="receiptIdentity"
              title="Envie sua identidade ou CNH"
            />
          </v-col>

          <v-col cols="12" md="6">
            <card-file-input
              v-model="formAddress.addressProof"
              ref="receiptAddress"
              title="Envie seu comprovante de endereço"
            />
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="12" sm="8" md="6" xl="4">
            <v-btn
              class="text-none rounded-xl secondary--text"
              color="primary2"
              large
              block
              @click="submit()"
            >
              Salvar alterações
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>

    <SentAnalysis ref="sentanalysis" />

    <loader-hover v-if="loading" />
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import {
  displayAlert,
  onlyNumber,
  blockNumber,
  formatDate,
  revertDate,
  currencyMask,
  parseNumber,
} from "@/utils";
import { request } from "@/services";
import { getAddress } from "@/services/viacep";
import { cpf, cnpj } from "cpf-cnpj-validator";
import SentAnalysis from "@/components/account/SentAnalysis.vue";
let docInterval = null;

export default {
  data() {
    return {
      loading: false,
      valid: false,
      validDocument: true,
      validAddress: true,
      pixTypes: [
        {
          label: "CPF ou CNPJ",
          value: "CPF ou CNPJ",
        },
        {
          label: "Celular",
          value: "Celular",
        },
        {
          label: "E-mail",
          value: "E-mail",
        },
        {
          label: "Chave aleatória",
          value: "Chave aleatória",
        },
      ],
      liquidityItems: [
        {
          label: "Mensal",
          value: "monthly",
        },
        {
          label: "Semestral",
          value: "semiannual",
        },
        {
          label: "Anual",
          value: "yearly",
        },
      ],
      contactItems: [
        {
          label: "E-mail",
          value: "email",
        },
        {
          label: "Celular",
          value: "phone",
        },
        {
          label: "Whatsapp",
          value: "whatsapp",
        },
      ],
      formPersonal: {
        name: "",
        birthDate: "",
        phone: "",
        document: "",
        profession: "",
        incomeEstimate: "",
        liquidity: "",
        contactType: "",
        identityProof: null,
      },
      formAddress: {
        street: "",
        number: "",
        complement: "",
        zipCode: "",
        neighborhood: "",
        city: "",
        state: "",
        addressProof: null,
      },
      formPix: [
        {
          id: "",
          keyType: "",
          pixKey: "",
        },
      ],
    };
  },

  components: { SentAnalysis },

  mounted() {
    this.startForm();
  },

  watch: {
    ["formPersonal.document"](val) {
      if (val.length === 14 || val.length === 18) {
        if (this.validDocument) return (this.validDocument = false);

        if (docInterval) clearInterval(docInterval);

        docInterval = setTimeout(() => {
          this.loading = true;

          setTimeout(() => {
            this.handleDocumentValidation();
          }, 1000);
        }, 1000);
      }
    },

    ["formAddress.zipCode"](val) {
      if (val.length === 9) {
        if (this.validAddress) return (this.validAddress = false);

        this.loading = true;

        setTimeout(() => {
          this.handleAddressValidation();
        }, 1000);
      }
    },
  },

  computed: {
    ...mapState(["user"]),

    bankRules() {
      return [
        (v) => !!v || "Campo obrigatório",
        (v) => /^[0-9-]*$/.test(v) || "Numeração inválida",
      ];
    },

    _profilePicture() {
      return this.$store.getters.apiURL + this.user.profilePhoto;
    },
  },

  methods: {
    async submit() {
      if (!this.validDocument) await this.$refs.document.handleInput("");
      if (!this.validAddress) await this.$refs.zipCode.handleInput("");

      if (
        !this.$refs.form.validate() ||
        !this.$refs.receiptIdentity.validate() ||
        !this.$refs.receiptAddress.validate()
      )
        return;

      try {
        this.loading = true;

        await this.submitPersonal();
        await this.submitAddress();
        await this.submitBankKey();

        this.refresh();
        this.handleSetAnalysis();
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    async submitPersonal() {
      try {
        const payload = {
          method: "uploadUserData",
          nome_razao: this.formPersonal.name,
          cpfCnpj: this.formPersonal.document.replaceAll(/[^0-9]/gim, ""),
          dataNascimento: revertDate(this.formPersonal.birthDate),
          telefone: this.formPersonal.phone.replaceAll(/[^0-9]/gim, ""),
          profissao: this.formPersonal.profession,
          estRenda: parseNumber(this.formPersonal.incomeEstimate),
          liquidez: this.formPersonal.liquidity,
          prefContato: this.formPersonal.contactType,
          docFrente: this.formPersonal.identityProof,
          docVerso: this.formPersonal.identityProof,
        };

        await request(payload);
      } catch (err) {
        this.displayAlert(err.message, 1);
      }
    },

    async submitAddress() {
      try {
        const payload = {
          method: "uploadUserResidential",
          cep: this.formAddress.zipCode.replaceAll(/[^0-9]/gim, ""),
          estado: this.formAddress.state,
          rua: this.formAddress.street,
          numero: this.formAddress.number,
          cidade: this.formAddress.city,
          bairro: this.formAddress.neighborhood,
          complemento: this.formAddress.complement,
          imgComprovante: this.formAddress.addressProof,
        };

        await request(payload);
      } catch (err) {
        this.displayAlert(err.message, 1);
      }
    },

    async submitBankKey() {
      for (let i = 0; i < this.formPix.length; i++) {
        const element = this.formPix[i];

        if (element.id === "") {
          try {
            const payload = {
              method: "addUserBankKey",
              chave: element.pixKey,
              tipoChave: `Chave ${element.keyType}`,
            };
            await request(payload);
          } catch (err) {
            this.displayAlert(err.message, 1);
          }
        }
      }
    },

    async submitProfileImage(e) {
      try {
        this.loading = true;

        const payload = {
          method: "uploadProfilePhoto",
          Image: e.target.files[0],
        };

        await request(payload).then(() => {
          this.refresh();
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    async handleDocumentValidation() {
      const payload = this.formPersonal.document.replaceAll(/[^0-9]/gim, "");
      let result = false;

      if (payload.length === 11) result = cpf.isValid(payload);
      else if (payload.length === 14) result = cnpj.isValid(payload);

      if (!result) {
        this.validDocument = false;
        this.displayAlert("CPF/CNPJ informado inválido", 1);
      } else this.validDocument = true;

      this.loading = false;
    },

    async handleAddressValidation() {
      try {
        this.loading = true;

        await getAddress(this.formAddress.zipCode).then((res) => {
          if (!res.data.cep) {
            this.validAddress = false;
            this.displayAlert("CEP informado inválido", 1);

            return;
          }

          this.$refs.street.handleInput(res.data.logradouro);
          this.$refs.complement.handleInput(res.data.complemento);
          this.$refs.neighborhood.handleInput(res.data.bairro);
          this.$refs.city.handleInput(res.data.localidade);
          this.$refs.state.handleInput(res.data.uf);

          this.validAddress = true;
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    async removeBankAccount(index) {
      const element = this.formPix[index];

      if (!element.id) this.formPix.splice(index, 1);
      else {
        try {
          this.loading = true;
          const payload = {
            method: "deleteUserBankKey",
            idconta: element.id,
          };

          await request(payload).then(() => {
            this.refresh();
          });
        } catch (err) {
          this.displayAlert(err.message, 1);
        } finally {
          this.loading = false;
        }
      }
    },

    refresh() {
      this.$root.$emit("refresh-user");

      setTimeout(() => {
        this.startForm();
      }, 500);
    },

    handleChangeImage() {
      window.addEventListener("focus", () => {}, { once: true });
      this.$refs.uploader.click();
    },

    addNewPixKey() {
      this.formPix.push({
        id: "",
        keyType: "",
        pixKey: "",
      });
    },

    startForm() {
      const personal = this.user.dadosPessoais;
      const residential = this.user.dadosResidencial;
      const pix = this.user.chavesBancarias;

      // personal
      if (Object.keys(personal).length) {
        this.$refs.name.handleInput(personal.nome_razao);
        this.$refs.birthDate.handleInput(formatDate(personal.dataNascimento));
        this.$refs.phone.handleInput(personal.telefone);
        this.$refs.document.handleInput(personal.cpfCnpj);
        this.$refs.profession.handleInput(personal.profissao);
        this.$refs.incomeEstimate.handleInput(currencyMask(personal.estRenda));
        this.$refs.liquidity.handleInput(personal.liquidez);
        this.$refs.contactType.handleInput(personal.prefContato);
      }

      // residential
      if (Object.keys(residential).length) {
        this.$refs.street.handleInput(residential.rua);
        this.$refs.number.handleInput(residential.numero);
        this.$refs.complement.handleInput(residential.complemento);
        this.$refs.zipCode.handleInput(residential.cep);
        this.$refs.neighborhood.handleInput(residential.bairro);
        this.$refs.city.handleInput(residential.cidade);
        this.$refs.state.handleInput(residential.estado);
      }

      // bank
      if (pix.length) {
        for (let i = 0; i < pix.length; i++) {
          if (this.formPix.length !== pix.length) this.addNewPixKey();
          setTimeout(() => {
            const element = pix[i];
            this.formPix[i].id = element.id;
            this.$refs.keyType[i].handleInput(
              element.tipoChave.replace("Chave ", "")
            );
            this.$refs.pixKey[i].handleInput(element.chave);
          });
        }
      }
    },

    handleSetAnalysis() {
      this.$refs.sentanalysis.open();
    },

    displayAlert,

    onlyNumber,

    blockNumber,
  },
};
</script>

<style></style>
